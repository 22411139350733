@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

/* Modal backdrop */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Modal content */
.modal-content {
    background-color: white;
    color: black;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-header {
    text-align: center;
    margin-bottom: 20px;
}

.modal-title {
    margin: 0;
    font-size: 1.5em;
}

.calendar-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

/* Customize the calendar appearance */
.custom-calendar {
    border: none;
    font-family: Arial, sans-serif;
}

.custom-calendar .react-calendar__tile--now {
    background: #e0e0e0;
}

.custom-calendar .react-calendar__tile--active {
    background: #e93e0e;
    color: white;
}

.input-grid {
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 15px 10px;
    align-items: start;
}

.input-grid label {
    font-size: 14px;
    font-weight: normal;
    text-align: left;
    padding-top: 8px;
}

.input-content {
    display: flex;
    align-items: center;
}

.bordered-input {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    font-size: 14px;
    width: 100%;
    height: 36px;
    box-sizing: border-box;
}

.time-selection {
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
}

.time-selection select {
    width: 70px;
}

.time-selection span {
    margin: 0 5px;
}

.action-button {
    width: 100%;
    padding: 10px;
    background-color: #e93e0e;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1em;
    cursor: pointer;
    margin-top: 15px;
}

.action-button:hover {
    background-color: #cf370d;
}

.time-row {
    margin-bottom: 2px !important;
}

.email-row {
    margin-top: 0px;
}

.input-grid select,
.input-grid input {
    height: 36px;
    box-sizing: border-box;
}

.error-message {
    color: #ff0000;
    margin-top: 10px;
    text-align: center;
}
