/* calendarStyles.css */
.react-calendar {
    border: none !important;
    font-size: 1rem !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif !important;
}

.react-calendar abbr[title] {
    text-decoration: none !important;
}

.react-calendar__tile--active {
    background: #0b1320 !important;
    color: white !important;
    border-radius: 10px;
}

.react-calendar__tile--now {
    background: #e93e0e !important;
    opacity: 75%;
    color: white !important;
    border-radius: 10px;
}

.modal-content {
    max-height: 90vh; /* This limits the modal's height */
    overflow-y: auto; /* This enables vertical scrolling if the content exceeds the max height */
}

.react-calendar__tile--hasActive {
    border-radius: 10px; /* If part of the selected range */
}
