/* client/src/components/styles/login.css */

.login-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.login-card {
    width: 100%;
    max-width: 28rem; /* 448px */
    margin: 0 1rem;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    overflow: hidden;
}

.login-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 1rem 1rem;
    background-color: #f9fafb;
}

.login-logo {
    width: 80px; /* Maintain the width */
    height: auto; /* Allow height to adjust automatically */
    max-height: 80px; /* Set a maximum height if needed */
    border-radius: 0; /* Remove the circular border */
    object-fit: contain; /* Ensure the entire image is visible */
}

.login-title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #0b1320;
    margin-top: 1rem;
}

.login-subtitle {
    font-size: 0.875rem;
    color: rgba(11, 19, 32, 0.7);
    text-align: center;
    margin-top: 0.5rem;
}

.login-content {
    text-align: center;
    padding: 1rem 1rem 2rem;
}

.login-content p {
    color: #0b1320;
    margin-bottom: 1.5rem;
}

.login-footer {
    padding: 0 1rem 2rem;
}

.zoho-login-button {
    width: 100%;
    background-color: #e93e0e;
    color: white;
    padding: 0.75rem;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

.zoho-login-button:hover {
    background-color: #d8370d;
}
